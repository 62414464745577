.App {
  text-align: center;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.image-class {
  max-width: 100px;
  height: auto;
  display: block;  
  margin: 0 auto;
}
